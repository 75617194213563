label[for="guess"] {
  margin-top: 1rem;
}

label[for="guess"] + p {
  margin: .5rem 0;
}

#guess {
  display: block;
}
