.input-container {
  margin: auto;
  max-width: 768px;
  padding: 0 1rem;
}

.input-container strong {
  font-weight: bold;
  text-shadow: 0 0 2px;
}

.input-container h1 {
  color: #a37bff;
  text-shadow: 0 0 4px;
  text-align: center;
  margin: 0;
  padding: 2rem 0 1rem 0;
}

.input-container .error {
  color: #f56d6d;
  font-weight: bold;
  text-shadow: 0 0 2px;
  margin: 0 0 .75rem 0;
}

.input-container textarea {
  display: block;
  margin: .5rem 0;
  padding: 4px;
  box-sizing: border-box;
  width: 100%;
  resize: vertical;
}
